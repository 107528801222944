.width-dragger {
  width: 5px;
  background: #002b36;
  cursor: ew-resize;
  padding: 4px 0 0;
  borderTop: 1px solid #ddd;
}

.height-dragger {
  height: 1px;
  width: 100%;
  background: #002b36;
  cursor: ns-resize;
  padding: 4px 0 0;
  borderTop: 1px solid #ddd;
}

.tabs-wrapper {
  display: flex;
  justify-content: space-between;
  height: 28px;
  width: 100%;
  background: #282c34;
}

.tabs-bar {
  width: 100%;
  height: 2px;
  background: #282c34;
}

.tab {
  color: white;
  display: flex;
  justify-contet: space-between;
  align-items: center;
  padding: 0 8px 0 5px;
  width: 150px;
  flex-shrink: 1;
  border-radius: 5px; 
  font-size: 12px;
  cursor: pointer;
}

.tab-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  padding-left: 10px;
}

.add-tab {
  padding-right: 15px;
  padding-top: 3px;
  cursor: pointer;
  width: 3%;
  display: flex;
  justify-content: end;
  color: white;
}

.tabs-container {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.divider {
  width: 0;
  height: 20px;
  border-left: 1px solid grey;
  align-self: center;
}

