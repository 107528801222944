.wrapper {
  background: #fdf6e3;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.container {
  max-width: 600px;
  padding-top: 20px;
  height: 100%;
}

h1 {
  margin: 0;
}

li {
  cursor: pointer;
}