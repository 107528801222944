.output {
  background: #073642;
  color: white;
  padding: 5px 5px 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.commands {
  margin: auto;
  height: 38px;
  align-items: center;
  padding-left: 10px;
  background: #225866;
  color: white;
  gap: 8px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  
}

.command {
  margin-right: 8px;
}
